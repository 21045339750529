// import React, { useState } from 'react';
import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { Formio } from 'formiojs';
import { useNavigate } from 'react-router-dom';
import "../styles/Popup.css"

// Modal style configuration (you can adjust the styles as needed)
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

// Bind modal to the root element of your app
Modal.setAppElement('#root');

const ProductPromoFormModal = (propsB) => {
    const navigate = useNavigate();
    const [submissionData, setSubmissionData] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formData, setFormData] = useState(null);

    const getStoredData = () => {
        const savedData = localStorage.getItem('formData');
        if (savedData) {
          return JSON.parse(savedData); // Parse the string back into an object
        }
        return null; // Return null if no data is found
      };
    const generatePrompt = (props) => {
        return {
            heading: propsB.heading,
            prompt: `Each ad will be crafted to maximize variety in length and style, including 1 long ad rich in detail and 1 medium-length ads with unique messaging styles. Ads are provided in sets of two and the final ad in each set is the longest, Ads avoid embedded links and instead clearly display URLs for social platforms.
                **Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            // prompt: `Generate 5 promotional posts using the following info:
            //     Brand Name & Niche: '${props.brandNameNiche}'
            //     Target Audience: '${props.targetAudience}'
            //     Product Name: '${props.productName}'
            //     Key Features: '${props.keyFeatures}'
            //     Product URL: '${props.productURL}', You can get the data from the URL as well.
            //     Tone/Voice of Content: '${props.toneVoiceContent}'
            //     Each post should focus on '${props.productName}', highlighting the unique benefits and key features that address my target audience: '${props.targetAudience}'s needs and drive them to take action.
            //     Each ad will be crafted to maximize variety in length and style, including 1 long ad rich in detail and 1 medium-length ads with unique messaging styles. Ads are provided in sets of two and the final ad in each set is the longest, Ads avoid embedded links and instead clearly display URLs for social platforms.
            //     **Compliance Standards**: All outputs comply with FTC guidelines and Facebook Ads policies, avoiding unverified claims, fabricated testimonials, or exaggerated promises. Testimonials will not be included unless verified. Outputs maintain a fact-based, benefit-driven approach, avoiding any content that could mislead or imply guaranteed results.`,
            // prompts: [
            //     `Exclusive Offer: Announce a limited-time offer on '${props.productName}', emphasizing how it helps '${props.targetAudience}' solve key challenges. Check out the offer now at '${props.productURL}'!`,
            //     `Product Benefits: Highlight how '${props.productName}' offers top-tier features like '${props.keyFeatures}', making it a must-have for '${props.targetAudience}'.`,
            //     `Why Choose Us? Show why '${props.brandNameNiche}' stands out, with '${props.productName}' being the perfect solution for '${props.targetAudience}''s needs.`,
            //     `Customer Testimonials: Share real stories from '${props.targetAudience}' who love '${props.productName}', focusing on their experience with key features like '${props.keyFeatures}'.`,
            //     `Special Discount: Encourage your audience to grab '${props.productName}' at a discounted price while emphasizing its powerful features.`,
            //     `Limited-Time Bundle: Promote a bundle deal with '${props.productName}', highlighting key features and driving urgency with limited-time pricing.`,
            //     `Product Launch: Announce the launch of '${props.productName}', outlining how its unique features can address '${props.targetAudience}''s pain points.`,
            //     `Free Demo/Trial: Offer a free demo or trial of '${props.productName}', giving '${props.targetAudience}' a risk-free opportunity to experience '${props.keyFeatures}'.`,
            //     `Referral Program: Promote a referral program tied to '${props.productName}', incentivizing '${props.targetAudience}' to spread the word about its benefits.`,
            //     `Early Access: Offer early access to '${props.productName}' for loyal customers, focusing on exclusivity and key features.`,
            //     `Core Value Highlight: Show how '${props.productName}' aligns with '${props.brandBioElevatorPitch}' and meets the needs of '${props.targetAudience}'. Include a CTA to explore more at '${props.productURL}'.`,
            //     `Key Features Breakdown: Detail the top '${props.keyFeatures}' of '${props.productName}', explaining how they solve common problems for '${props.targetAudience}'.`,
            //     `Social Proof: Share a success story or user testimonial about '${props.productName}', highlighting how '${props.targetAudience}' benefits from using it.`,
            //     `Product Comparison: Compare '${props.productName}' to competitors, showing why its key features make it the better choice for '${props.targetAudience}'.`,
            //     `Limited Edition: Create urgency by announcing a limited-edition version of '${props.productName}', available exclusively on '${props.socialMediaPlatforms}'.`,
            //     `Seasonal Promotion: Tie '${props.productName}' to a seasonal event or holiday, offering special features or discounts tailored to '${props.targetAudience}'.`,
            //     `How-To Guide: Post a quick guide showing '${props.targetAudience}' how to use '${props.productName}' to make the most out of its '${props.keyFeatures}'.`,
            //     `Product Benefits Series: Start a series of posts diving into each key feature of '${props.productName}', showing how each helps '${props.targetAudience}' overcome obstacles.`,
            //     `Product Feature Poll: Engage '${props.targetAudience}' by asking them to vote on their favorite feature of '${props.productName}', directing them to '${props.productURL}'.`,
            //     `Behind the Scenes: Share behind-the-scenes content about the making of '${props.productName}', focusing on the attention to detail that benefits '${props.targetAudience}'.`
            // ],
        }
    }
    // Open the modal
    const openModal = () => {
        setModalIsOpen(true);
    };

    // Close the modal
    const closeModal = () => {
        setModalIsOpen(false);
    };

    // Form.io form JSON (same as provided)
    const formJSON = {
        "display": "form",
        "components": [
            {
                "type": "columns",
                "columns": [
                    {
                        "components": [
                            {
                                "label": "Product Name",
                                "placeholder": "Enter the product name",
                                "type": "textfield",
                                "key": "productName",
                                "input": true,
                                "tableView": true,
                                "validate": {
                                    "required": true,
                                }
                            }
                        ],
                        "width": 6,
                    },
                    {
                        "components": [
                            {
                                "label": "Product URL",
                                "placeholder": "Enter the product URL",
                                "type": "textfield",
                                "key": "productURL",
                                "input": true,
                                "validate": {
                                    "required": true,
                                    "pattern": "^https?://.*$",
                                    "customMessage": "Please enter a valid URL starting with http or https."
                                },
                                "tableView": true
                            }
                        ],
                        "width": 6,
                    }
                ],
            },
            {
                "type": "columns",
                "columns": [
                    {
                        "components": [
                            {
                                "label": "Product Description",
                                "placeholder": "Enter the product description",
                                "type": "textarea",
                                "key": "productDescription",
                                "input": true,
                                "tableView": true,
                                "validate": {
                                    "required": true,
                                }
                            }
                        ],
                        "width": 6,
                    },
                    {
                        "components": [
                            {
                                "label": "Key Features",
                                "placeholder": "Enter the product's Key Features",
                                "type": "textarea",
                                "key": "keyFeatures",
                                "input": true,
                                "validate": {
                                    "required": true,
                                },
                            }
                        ],
                        "width": 6,
                    }
                ],
            },
            {
                "type": "button",
                "label": `${propsB.button}`,
                "input": true,
                "key": "submit",
                "tableView": false
            }
        ]
    }

    // Render the Form.io form inside the modal
    const renderForm = async () => {
        const formElement = document.getElementById('formio');
        if (formElement) {
            // Await the creation of the form
            const form = await Formio.createForm(formElement, formJSON);
            
            // Load saved data from localStorage, if available
            const savedData = JSON.parse(localStorage.getItem('formData'));
            if (savedData) {
                form.submission = { data: savedData }; // Pre-fill the form with saved data
            }

            // Save form data to localStorage on form submit
            form.on('submit', (submission) => {
                setSubmissionData(submission.data);
                saveDataToLocalStorage(submission.data); // Save data
                // navigate('/content'); // Navigate to another page after submission
                const props = getStoredData();
                const prompt = generatePrompt(props)
                // console.log(prompt)
                navigate('/results', { state: prompt })
                closeModal();
            });

            // Optional: Save form data to localStorage on form change (autosave)
            form.on('change', (submission) => {
                saveDataToLocalStorage(submission.data); // Autosave form data
            });
        }
    };

    // Function to save form data to localStorage
    const saveDataToLocalStorage = (formData) => {
        localStorage.setItem('formData', JSON.stringify(formData));
    };

    return (
        <div>
            {/* Trigger Button to open the modal */}
            <button onClick={openModal} className="font-poppins text-[15px] font-semibold rounded-full bg-white py-[10px] px-[30px]">
                {propsB.button}
            </button>

            {/* Modal */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={renderForm} // Render the Form.io form after the modal opens
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Promotional Content Form"
            >
                <h2 className='text-center text-3xl font-bold my-10 uppercase text-blue'>Enter Product Details</h2>
                {/* Form.io form will be rendered here */}
                <div id="formio" />

                <button onClick={closeModal} className="px-4 py-2 mt-4 bg-red-500 text-white rounded hover:bg-red-700 popup-close-btn">
                    &#10006;
                </button>
            </Modal>
        </div>
    );
};

export default ProductPromoFormModal;
